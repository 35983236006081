import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'

//土地展首页数据
export function hangpaiHomeList(params) {
	return loginRequest({
    url: '/frontend/website/hang/homepage',
		method: 'post',
		data:Qs.stringify(params)
	})
}

//初始化
export function hangpaiInit(params) {
	return loginRequest({
    url: '/frontend/website/hang/init',
		method: 'post',
		data:Qs.stringify(params)
	})
}

//区
export function hangpaiDistrict(params) {
	return loginRequest({
    url: '/frontend/website/hang/district',
		method: 'post',
		data:Qs.stringify(params)
	})
}

//列表
export function hangpaiList(params) {
	return loginRequest({
    url: '/frontend/website/hang/list',
		method: 'post',
		data:Qs.stringify(params)
	})
}

//详情
export function hangpaiDetail(params) {
	return loginRequest({
    url: '/frontend/website/hang/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}

